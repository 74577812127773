import React, { useState } from 'react';
import languageContext from "./languageContext"

const LanguageState = (props) => {
    const [lang,setLang]=useState(0);
    const [sessionTimeout,setSessionTimeout]=useState(false);
    const [phone,setPhone]=useState('');
    const [chats,setChats]=useState([])
    const [dataApi, setData] = useState([]);
    const [alertPrompt,setAlert]=useState(false);
    const [message,setMessage]=useState('')
    const [bookappointment, setbookappointment]=useState(null);
    const [labbook, setlabbook]=useState(null);
    const [reminder, setreminder]=useState(null);
    const [isLeftPanel, setIsLeftPanel]=useState(false);
    const [isRightPanel, setIsRightPanel]=useState(false);
    return (
        <languageContext.Provider value={{alertPrompt,setAlert,message,setMessage, lang,setLang,sessionTimeout,setSessionTimeout,phone,setPhone,chats,setChats,dataApi,setData, bookappointment,setbookappointment,labbook,setlabbook,reminder, setreminder,  isLeftPanel,setIsLeftPanel, isRightPanel,setIsRightPanel}}>
            {props.children}
        </languageContext.Provider>
    );
};

export default LanguageState;
