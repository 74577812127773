import { FaArrowLeft } from "react-icons/fa";
import { GiHamburgerMenu } from "react-icons/gi";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { RiLogoutCircleRLine } from "react-icons/ri";
import { RiChatHistoryLine } from "react-icons/ri";
import languageContext from "../context/language/languageContext";
import { useContext } from "react";
import { useState } from "react";

const Navbar = () => {
  const {
    setChats,
    setbookappointment,
    bookappointment,
    setIsLeftPanel,
    isLeftPanel,
    labbook,
    labsetbook,
    
    sessionTimeout,
    isRightPanel,
    setIsRightPanel
  } = useContext(languageContext);
  const navigate = useNavigate();
  const location = useLocation();
  const url = location.state?.url || "/"; // Retrieve URL from state with fallback

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("login");
    navigate("/");
  };
  const handleBackArrowPress = () => {
    navigate(url);
  };
  const handleBookAppointment = () => {
    console.log("hhjhjjh");
    // Navigate to the /ask page and pass state
    // setbookappointment("Book My Next Appointment");
    console.log(bookappointment, "-=-=-=-=8787877887");
    navigate("/book_appointment");
  };


  const handlelabAppointment = () => {
    console.log("hhjhjjh");
    // Navigate to the /ask page and pass state
    // setbookappointment("Book My Next Appointment");
    console.log(labbook, "-=-=-=-=8787877887");
    navigate("/lab_book");
  };


  const handlereminder = () => {
    console.log("hhjhjjh");
    // Navigate to the /ask page and pass state
    // setbookappointment("Book My Next Appointment");
    // console.log(reminder, "-=-=-=-=8787877887");
    navigate("/reminder");
  };

  const handlehome=()=>{
    navigate("/ask");

  }
  const handleChecklist = () => {
    console.log("hhjhjjh");
    // Navigate to the /ask page and pass state
    setbookappointment("Checklist for Next Appointment");
    console.log(bookappointment);
    navigate("/ask");
  };
  console.log("localstorage value", localStorage.getItem("login"))
  return (
    <div className="bg-bgcolor w-full h-[80px] flex items-center justify-between">
      <div className="flex">
        {/* Back button with saved state */}

        {!localStorage.getItem("login") ? (
          <div
            className="w-[40px] h-[40px] bg-white rounded-full flex justify-center items-center ml-5"
            onClick={handleBackArrowPress}
          >
            <FaArrowLeft />
          </div>
        ) : (
          <>
            <div
              className="w-[40px] h-[40px] bg-white rounded-full flex justify-center items-center ml-5 backArrow"
              onClick={handleBackArrowPress}
            >
              <FaArrowLeft />
            </div>
            <div
              className="w-[40px] h-[40px] bg-white rounded-full flex justify-center items-center ml-5 hamburgerMenu"
              onClick={() => {
                setIsRightPanel(false)
                setIsLeftPanel(!isLeftPanel)
              }}
            >
              <RiChatHistoryLine />
            </div>
          </>
        )}
      </div>

      

      <div className="flex">
      {location.pathname.includes("/book_appointment") &&( <button
              onClick={handlehome}
              className="bg-slate-200 ml-2 rounded-lg my-auto px-2 py-2 cursor-pointer bookMyAppointment"
            >
              Home
            </button>
      )}

      {location.pathname.includes("/lab_book") &&( <button
              onClick={handlehome}
              className="bg-slate-200 ml-2 rounded-lg my-auto px-2 py-2 cursor-pointer bookMyAppointment"
            >
              Home
            </button>
      )}

{location.pathname.includes("/reminder") &&( <button
              onClick={handlehome}
              className="bg-slate-200 ml-2 rounded-lg my-auto px-2 py-2 cursor-pointer bookMyAppointment"
            >
              Home
            </button>
      )}
        {/* Conditional rendering: Only show if the URL contains '/ask' */}
        {location.pathname.includes("/ask") && (
          <>
            {/* {/* <Link 
                            to="/ask" 
                            // state={{book_appointment:"book appointment"}} 
                            > */}

            <button
              onClick={handleBookAppointment}
              className="bg-slate-200 ml-2 rounded-lg my-auto px-2 py-2 cursor-pointer bookMyAppointment"
            >
              Book My Next Appointment
            </button>

            <button
              onClick={handleChecklist}
              className="bg-slate-200 ml-2 rounded-lg my-auto px-2 py-2 cursor-pointer nextAppointment"
            >
              Checklist for Next Appointment
            </button>


            <button
              onClick={handlelabAppointment}
              className="bg-slate-200 ml-2 rounded-lg my-auto px-2 py-2 cursor-pointer bookMyAppointment"
            >
              Book My Lab Appointment
            </button>
            <button
              onClick={handlereminder}
              className="bg-slate-200 ml-2 rounded-lg my-auto px-2 py-2 cursor-pointer bookMyAppointment"
            >
              Book My Reminder
            </button>


            
            {/* </Link> */}

            {/* Adding state for /checklist */}
            {/* <Link 
                             to="/ask" 
                            state={{Checklist :"Checklist for Next Appointment"}} // Save current pathname in state
                        >
                            
                        </Link> */}
          </>
        )}
        <div
          className="w-[40px] h-[40px] bg-white rounded-full flex justify-center items-center mx-5 backArrow"
          onClick={logout}
        >
          <RiLogoutCircleRLine size="1.2rem" />
        </div>

        <div
          className="w-[40px] h-[40px] bg-white rounded-full flex justify-center items-center mx-5 hamburgerMenu"
          onClick={() => {
            setIsLeftPanel(false)
            setIsRightPanel(!isRightPanel)
          }}
        >
          <GiHamburgerMenu/>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
