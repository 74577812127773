import { BrowserRouter, Route, Routes } from "react-router-dom";
import LandingPage from "./pages/LandingPage";
import LoginPage from "./pages/LoginPage";
import Signup from "./pages/SignupPage";
import OtpVerify from "./pages/OtpPage";
import Verify from "./components/Verify";
import LanguageState from "./context/language/LanguageState";
import Profile from "./pages/ProfilePage";
import QuestionPage from "./pages/QuestionPage";
import SessionTimeoutPopup from "./components/Session";
import Alert from "./components/Alert";
import Checklist from "./pages/Checklist";
import Booking from "./pages/Booking";
import LabBook from "./pages/LabBooking"
import Reminderbook from "./pages/Reminderbooking"
import { messaging, getToken,onMessage} from './firebase';
import { useEffect } from 'react';
function App() {
  useEffect(() => {
    console.log("=-=-=-per,mission")
    // Request for notification permission
    const requestPermission = async () => {
      console.log("=-=-=-per,mission")
      const permission = await Notification.requestPermission();
      console.log(permission, "=-=-=-per,mission")
      if (permission === 'granted') {
        console.log("Notification permission granted.");
        // Get the device token
        getToken(messaging, {
          vapidKey: "BNGZ9Tk1_n-SnNgVMsmfxWjT4rxq1g6PfMQx8o-TGSJzMpdGj4bJ4NqqrAhCVYt7yxlhYGjFMfqK9vC8x4F89eA" // Required for web push
        }).then((currentToken) => {
          if (currentToken) {
            console.log("Current token:", currentToken);
          } else {
            console.log("No registration token available.");
          }
        }).catch((err) => {
          console.log("Error getting token:", err);
        });
      } else {
        console.log("Notification permission denied.");
      }
    };

    // Call the request permission function on component mount
    requestPermission();

    // Handle incoming messages
    onMessage(function(payload) {
      console.log("Message received in foreground: ", payload);
      // Customize the notification you want to show to the user
      const notificationTitle = payload.notification.title;
      const notificationOptions = {
        body: payload.notification.body,
        icon: payload.notification.icon,
        badge: payload.notification.badge,
      };
    
      new Notification(notificationTitle, notificationOptions);
    })
  }, []);
  return (
    <div className="App" >
      <BrowserRouter>
      <LanguageState>
        <Alert/>
        <SessionTimeoutPopup/>
        <Routes>
          <Route exact path="/" element={<LandingPage />} />
          <Route exact path="/login" element={<LoginPage />} />
          <Route exact path="/signup" element={<Signup />} />
          <Route
            exact
            path="/otp"
            element={
              <Verify>
                <OtpVerify />
              </Verify>
            }
          />
          <Route exact path="/account" element={<Profile/>}/>
          <Route exact path="/ask/:id" element={<QuestionPage/>}></Route>
          <Route exact path="/ask" element={<QuestionPage/>}></Route>
          <Route exact path="/checklist" element={<Checklist/>}></Route>
          <Route exact path="/booking" element={<Booking/>}></Route>
          <Route exact path="/book_appointment" element={<Booking/>}></Route>
          <Route exact path="/lab_book" element={<LabBook/>}></Route>
          <Route exact path="/reminder" element={<Reminderbook/>}></Route>
        </Routes>
        </LanguageState>
      </BrowserRouter>
    </div>
  );
}

export default App;
