import Navbar from "../components/Navbar"
import AskReminder from "../components/AskReminder";
import languageContext from "../context/language/languageContext";
import { useContext, useEffect, useState } from "react";    
const Booking = ()=>{
    console.log("Booking")
    const { reminder,setreminder } = useContext(languageContext);
    useEffect(()=>{
        setreminder("Book My New Reminder")
    }, [])
    return(
        <>
        <Navbar/>
        <div className="w-full overflow-y-auto"> {/* Make this section scrollable */}
                    < AskReminder reminder={reminder} setreminder={setreminder}/>
                </div>

        </>
    )
}
export default Booking