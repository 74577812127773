import { initializeApp } from 'firebase/app';
import { getMessaging, getToken,onMessage } from 'firebase/messaging';

// Your Firebase project configuration (get this from your Firebase Console)

// Initialize Firebase
const firebaseConfig = {
    apiKey: "AIzaSyBDjVbHMSm4QhlJGR6_umq8ZHq5DfXNZzQ",
    authDomain: "qcare-cda05.firebaseapp.com",
    projectId: "qcare-cda05",
    storageBucket: "qcare-cda05.firebasestorage.app",
    messagingSenderId: "984248762770",
    appId: "1:984248762770:web:048e065582eac177ec814b",
    measurementId: "G-LVZEJKQH10"
  };
const app =initializeApp(firebaseConfig);

// Get Firebase Messaging instance
const messaging = getMessaging(app);

if ('serviceWorker' in navigator) {
    navigator.serviceWorker
      .register('/firebase-messaging-sw.js')
      .then((registration) => {
        console.log('Service Worker registered successfully with scope:', registration.scope);
      })
      .catch((error) => {
        console.error('Service Worker registration failed:', error);
      });
  }



export { messaging, getToken, onMessage};
